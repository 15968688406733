import React, { FC, useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link, navigate } from 'gatsby';
import toast from 'react-hot-toast';
import { useUserContext } from 'common/UserProvider';

import { SIGNUP_URL } from 'common/const';
import { validateEmail, isBrowser } from 'common/utils';
import { emailStatus } from 'common/server';
import { IReactiveButtonStatus } from 'common/types';

import { Trans } from 'react-i18next';
import { H3, P } from '@global/Texts';
import { ReactiveButton, MokensSignupButton } from '@global/Buttons';

import GoogleIcon from 'images/google.svg';
import AppleIcon from 'images/apple.svg';

import '../styles.scss';

const Login: FC = () => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState([false, false]);
	const [userEmail, setUserEmail] = useState<string>('');
	const [otp, setOtp] = useState<string>('');
	const [error, setError] = useState<string[]>(['', '']);
	const { sendCode, validateCode } = useUserContext();
	const [validateButton, setValidateButton] = useState([IReactiveButtonStatus.DEFAULT, IReactiveButtonStatus.DEFAULT])

	let redirectAfterLogin = process.env.GATSBY_SITE_URL;

	if (isBrowser()) {
		redirectAfterLogin = window?.history?.state?.redirectAfterLogin || process.env.GATSBY_SITE_URL;
	}

	const handleCode = async () => {
		setError(['', error[1]]);
		setIsLoading([true, isLoading[1]]);
		setValidateButton([IReactiveButtonStatus.LOADING, validateButton[1]])

		const userEmailStatus = await emailStatus({ email: userEmail });

		if (userEmail && validateEmail(userEmail)) {
			if (!userEmailStatus) {
				toast.error(t('passwordlessSignUp.login.noUserError'));
				setValidateButton([IReactiveButtonStatus.ERROR, validateButton[1]])
				setError([t('passwordlessSignUp.login.noUserError'), error[1]]);
				setIsLoading([false, isLoading[1]]);
				return;
			}

			const response = await sendCode(userEmail);

			if (!response) {
				toast.error(t('passwordlessSignUp.login.serverError'));
				setError([t('passwordlessSignUp.login.serverError'), error[1]]);
				setValidateButton([IReactiveButtonStatus.ERROR, validateButton[1]])
				return;
			}
		} else {
			toast.error(t('passwordlessSignUp.login.invalidEmailDescription'));
			setError([
				t('passwordlessSignUp.login.invalidEmailDescription'),
				error[1],
			]);
			setIsLoading([false, isLoading[1]]);
			setValidateButton([IReactiveButtonStatus.ERROR, validateButton[1]])
			return;
		}

		toast.success(t('passwordlessSignUp.login.codeSent'));
		setValidateButton([IReactiveButtonStatus.SUCCESS, validateButton[1]])
		setError(['', error[1]]);
		setIsLoading([false, isLoading[1]]);
	}

	const handleValidateCode = async () => {
		setError([error[0], '']);
		setIsLoading([isLoading[0], true]);
		setValidateButton([validateButton[0], IReactiveButtonStatus.LOADING])
		if (!isLoading[1] && otp) {
			const response = await validateCode(userEmail, otp);
			if (!response) {
				setValidateButton([validateButton[0], IReactiveButtonStatus.ERROR])
				setError([error[0], t('passwordlessSignUp.login.serverError')]);
			} else {
				setValidateButton([validateButton[0], IReactiveButtonStatus.SUCCESS])
				navigate(-1)
			};
		} else {
			setValidateButton([validateButton[0], IReactiveButtonStatus.ERROR])
			setError([error[0], t('passwordlessSignUp.login.invalidOtpDescription')]);
		}
		setIsLoading([isLoading[0], false]);
	};

	return (
		<div className='new-profile'>
			<div className='new-profile-header'>
				<H3 className='py-2 text-center'>
					{t('passwordlessSignUp.login.title')}
				</H3>
			</div>
			<div className='new-profile-content py-12'>
				<P className='mb-4 text-orange'>
					{t('passwordlessSignUp.newProfile.description')}
				</P>
				<div className='mx-auto md:w-3/4'>
					<P className='mt-2 mb-2 mokens-input-passwordless-title'>
						{t('passwordlessSignUp.login.email')}
					</P>
					<div className='flex flex-col md:flex-row gap-4'>
						<input
							className='mokens-input-passwordless'
							placeholder={t('passwordlessSignUp.login.emailPlaceholder')}
							value={userEmail}
							onChange={(ev: any) => {
								setUserEmail(ev.target.value);
								if (validateButton[0] !== IReactiveButtonStatus.DEFAULT && validateButton[0] !== IReactiveButtonStatus.LOADING) {
									setValidateButton([IReactiveButtonStatus.DEFAULT, validateButton[1]])
								}
							}}
							type='email'
						/>
						<ReactiveButton
							className='validate-code-btn validate-code-btn-primary'
							status={validateButton[0]}
							onClick={handleCode}>
							{t('passwordlessSignUp.login.buttonSendcode')}
						</ReactiveButton>

					</div>
					<div className='text-pink py-2'>{error[0]}</div>
					<P className='mt-2 mb-2 mokens-input-passwordless-title'>
						{t('passwordlessSignUp.login.otp')}
					</P>
					<div className='flex flex-col md:flex-row gap-4'>
						<input
							className='mokens-input-passwordless flex-auto'
							placeholder={t('passwordlessSignUp.login.otpPlaceholder')}
							value={otp}
							onChange={(ev: any) => {
								setOtp(ev.target.value)
								if (validateButton[1] !== IReactiveButtonStatus.DEFAULT && validateButton[1] !== IReactiveButtonStatus.LOADING) {
									setValidateButton([validateButton[0], IReactiveButtonStatus.DEFAULT])
								}
							}}
							type='text'
						/>

						<ReactiveButton
							disabled={
								otp === '' ||
								!validateEmail(userEmail) ||
								!validateEmail(userEmail).length
							}
							className='validate-code-btn validate-code-btn-primary'
							status={validateButton[1]}
							onClick={handleValidateCode}>
							{t('passwordlessSignUp.login.button')}
						</ReactiveButton>

					</div>
					<div className='text-pink py-2'>{error[1]}</div>
				</div>
				<P className='mb-0 text-white uppercase text-center'>
					{t('passwordlessSignUp.login.social')}
				</P>
				<div className='flex justify-center gap-5 mt-4 mb-4'>
					<MokensSignupButton
						text=''
						variant='img'
						icon={GoogleIcon}
						onClick={() =>
							navigate(
								process.env.GATSBY_BASE_API_URL +
								`/social?provider=google-oauth2&response_type=code&flow=login&social_callback=${redirectAfterLogin}`
							)
						}
					/>
					<MokensSignupButton
						text=''
						variant='img'
						icon={AppleIcon}
						onClick={() =>
							navigate(
								process.env.GATSBY_BASE_API_URL +
								`/social?provider=apple&response_type=code&flow=login&social_callback=${redirectAfterLogin}`
							)
						}
					/>
				</div>
				<span className='flex h-auto justify-center items-end gap-1'>
					<Trans>
						{t('passwordlessSignUp.login.noAccount')}{' '}
						<Link className='hover:text-blue underline' to={SIGNUP_URL}>
							{' '}
							{t('passwordlessSignUp.login.signUp')}
						</Link>
					</Trans>
				</span>
			</div>
		</div>
	);
};

export default Login;
