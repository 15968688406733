import {
	ReactNode,
	CSSProperties,
	MouseEventHandler,
	FC,
	ButtonHTMLAttributes,
} from 'react';
import { Properties } from 'csstype';
import { IFluidObject } from 'gatsby-background-image';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { IRoundProps } from 'react-brackets';

interface ICommonProps {
	text: string | any;
	variant: 'primary' | 'secondary' | 'secondary-red' | 'img';
	className?: string;
	icon?: string;
	enabled?: boolean;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export interface IButtonProps
	extends ICommonProps,
		ButtonHTMLAttributes<HTMLButtonElement> {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	loading?: boolean;
}

export interface ILinkButtonProps extends ICommonProps {
	onClick: string;
}

export interface IMokensButtonProps extends ICommonProps {
	onClick?: ((arg: any) => any);
	color?: 'blue' | 'yellow' | 'red' | 'full-yellow';
	loading?: boolean;
	link?: string;
	target?: '_self' | '_blank';
}

export interface IMokensCartoonButtonProps extends ICommonProps {
	onClick?: ((arg: any) => any) | string;
	color?: 'yellow' | 'green' | 'youtube' | 'blue';
	loading?: boolean;
	link?: boolean;
	youtube?: boolean;
}

export interface IMokensStyledButtonProps {
  text: string;
  onClick?: ((arg: any) => any);
  link?: string;
  target?: '_self' | '_blank';
  disabled?: boolean;
  className?: string;
	textClassName?: string;
}

export enum FooterStyle {
	DARK = 0,
	LIGHT = 1,
}

export interface IPosition {
	labelId: 'design' | 'development' | 'sales';
	title: string;
	description: string;
	type: string;
	dedication: string;
	link: string;
}

export interface ICardProps {
	title?: string;
	description?: string;
	background?: 0 | 1 | 2 | 3 | 4 | 5;
	isCTA: boolean;
}

export interface IExternalLinkProps {
	link: string;
	label: string;
	className?: string;
}

export interface IIconLabelProps {
	icon: string;
	label: string;
}

export interface InputProps {
	text?: string;
	ref?: any;
	className?: string;
	placeholder?: string;
	readonly?: boolean;
}

export interface IJobOfferProps {
	labelId: string;
	title: string;
	description: string;
	type: string;
	dedication: string;
	link: string;
}

export interface IPillProps {
	text: string;
	className?: string;
}

type availableSocialMedia = 'discord'| 'tiktok'| 'youtube' | 'twitch' | 'x' | 'instagram' | 'medium' | 'linkedin' | 'telegram'

export interface ISocialBar {
	size?: 'sm' | 'lg';
	linkedSocialMedia?: availableSocialMedia[];
	className?: string;
}
export interface IToken {
	identifier: string;
	token: string;
	color: 'orange' | 'green';
	url?: string;
}

export interface ITokensProps {
	tokens: IToken[];
}

export interface IFooterItemLink {
	title: string;
	url: string;
	isExternal?: boolean;
	isDisabled?: boolean;
	gif?: string;
}

export interface IFooterItem {
	title: string;
	links: IFooterItemLink[];
}

export interface ITextProps {
	children: ReactNode;
	className?: string;
	style?: CSSProperties;
	isHtmlHeader?: boolean;
}

export interface IHeaderLink {
	to: string;
	text: string;
	enabled?: boolean;
	soon?: boolean;
	className?: string;
	isExternal?: boolean;
}

export interface ISEOParams {
	title: string;
	description: string;
	lang?: string;
	meta?: any;
	imgPath?: string;
	imgAlt?: string;
}

export interface IHeaderChild {
	links?: IHeaderLink[];
	user?: any;
}

export interface ITokenDetail {
	token: string;
	tokenColor: string;
	pillColor: string;
	tokenImg?: string;
	tokenAddress: string;
	howToGetItItems: string[];
	useCasesItems: string[];
}

export interface IUserParams {
	email: string;
	otp?: string;
	mailingList?: boolean;
}

export interface IUpdateUserParams {
	dbId: string;
	email?: string;
	authId?: string;
	nickname?: string;
	wallet?: string;
	removeWallet?: boolean;
	signature?: string;
	icon?: string;
	mailingList?: boolean;
}

export interface IWalletParams {
	wallet: string;
}

export interface IEmailParams {
	email: string;
}

export interface IProviderParams {
	provider: string;
	providerId: string;
}

export interface IWalletSignatureParams extends IWalletParams {
	signature: string;
	dbId: string;
}

export interface IMokensUser {
	id: string;
	nonce: number;
	id_token: string;
	access_token: string;
	refresh_token: string;
	address?: string;
	email?: string;
}

export interface IDynamoUser {
	userId: string;
	nonce: number;
	userWallet?: string;
	userEmail?: string;
	userNickname?: string;
}

export interface IServerResponse {
	statusCode: number;
	body: {
		success: boolean;
		data: object;
	};
}

export interface IAlternateServerResponse {
  data: {
    id: string;
    nonce: number;
  }
  success: boolean;
}

export type GoogleAnalyticsEvent = {
	key: 'sign_up';
	params: {
		event_category:
			| 'new_profile'
			| 'link_wallet'
			| 'wallet_agreement'
			| 'link_success'
			| 'wrong_network';
		value: boolean;
	};
};

export interface IBetaRegisterData {
  emailAddress: string;
  selectedDevice: string;
}

export interface IUserContextState {
	user: any;
	setUser: any;
	walletManager: any;
	sendCode: any;
	validateCode: any;
	loaded: boolean;
	logout: any;
	address: any;
	update: any;
  betaRegisterData: IBetaRegisterData;
  setBetaRegisterEmail: any;
  setDevice: any;
  resetRegisterBetaData: any;
}

export interface IRegisterBetaProps {
  isMobile: boolean;
}

export interface IRadioButtonProps {
  checked?: boolean;
}

export interface ISlide {
  type: 'img' | 'video'
  imageData?: IGatsbyImageData;
  videoUrl?: string
  title: string;
  altAid: string;
  screenshots?: ISlide[];
}

export interface ISliderProps {
  spaceBetween?: number;
  slidesPerView?: number | 'auto';
  centeredSlides?: boolean;
  pagination?: boolean;
  navigation?: boolean;
  loop?: boolean;
  className?: string;
  style?: Properties<string | number, string & {}>;
  slides: ISlide[];
  setCurrentGallery?: (slides: ISlide[]) => void | null;
}

export interface IFAQPanel {
  header: string;
  details: string;
}


export interface IDividerProps  {
	dividerImage?: string;
	middleImage?: string
	size?: 'sm' | 'md' |'lg'
}

export interface IDownloadButtonsProps {
  text: string;
  icon: string;
  url: string;
}

export interface IDetailsInfo {
	info: string;
}

export interface IScoreProps {
	score: number;
	image: IGatsbyImageData;
}
export interface IFamilyData {
	summaryBg: IFluidObject;
	sumaryMokenLineups: [IGatsbyImageData, IGatsbyImageData];
	backgroundImageFluid: IFluidObject;
	backgroundPattern: IFluidObject;
	dividerImage: string;
	familyLogo: IGatsbyImageData;
	familyName: string;
}

export interface IFamilyProps extends ISectionQueryData {
  setMokenData: ((arg: any) => any);
	familyData: IFamilyData;
}
export interface IMokenData {
	ABL: number;
	GraphicId: string;
	INT: number;
	Id: number;
	MokenName: string;
	MokenPrefab: string;
	Position: number;
	Rarity: number;
	STA: number;
	STR: number;
	UUID: string;
	Collection: string;
}

export interface IMokenCardProps {
  handleOnClick: ((arg: any) => any);
	imageData: IGatsbyImageData;
	mokenData: IMokenData;
	size: 'xs' | 'sm' | 'md' | 'lg';
	disabled?: boolean;
  className?: string;
	onImageLoaded?: any;
}

export interface IPaginatedGrid extends ISectionQueryData {
  setMokenData: ((arg: any) => any);
	mokenArray: IMokenData[];
	ownedMokensIds: number[];
}

export interface IMokensFamiliesSection extends ISectionQueryData {
	handleSelectMoken: ((arg: any) => any);
}

export interface IStatBoxProps {
	label: string;
	value: number;
	icon: IGatsbyImageData;
	iconAlt: string;
}

export interface IMokenDetailProps extends ISectionQueryData {
	moken: IMokenData;
	className?: string;
	closeModal: () => void;
}
export interface IDownloadButtonsContainerProps {
	buttons: IDownloadButtonsProps[];
	ready: boolean;
}

export interface ITournamentTheme {
  id: string;
  color: string;
  logoImage: IGatsbyImageData;
  bannerImage: IGatsbyImageData;
  textData: ITournamentInformation;
  dotColor?: string;
}

export interface ITournamentSlidesProps {
  currIndex: number;
  themes: ITournamentTheme[];
}

export interface ITournamentInformation {
  mainBody: {
    part1: string;
    part2: string;
    highlight: string;
  }
  additional: {
    main: string;
    highlight: string;
  }
}

export interface IPaginationProps {
  currIndex: number;
  pages: ITournamentTheme[];
  setCurrentIndex: ((arg: number) => void);
  handleCallback?: ((arg?: any) => any);
  size: 'lg' | 'md' | 'sm';
  disabled: boolean;
  dotColor?: string;
  className?: string;
}

export interface IFadeSliderProps {
  pages: any;
  currentIndex: number;
  setCurrentIndex: ((arg: number) => void);
  handleCallback?: ((arg?: any) => any);
  children: ReactNode;
  disablePagination?: boolean;
  paginationStyles?: string;
  paginationSize?: 'lg' | 'md' | 'sm';
  paginationDotColor?: string;
  fadeInterval?: number;
  className?: string;
}

export interface IFadeSlideProps {
  children: ReactNode;
  initial?: Object;
  animate?: Object;
  transition?: Object;
  className?: string;
}

export interface IFooterLink {
  title: string;
  url: string;
}

export interface IFooterLinks {
  links: IFooterLink[];
}

export interface ISectionQueryData {
  imagesData: any;
}

export interface IAlbumAndTournament {
	imagesData: any;
	isUserLogged: boolean;
}

export interface ISVGComponentProps {
	disableBackground?: boolean;
  className?: string;
}

export enum IReactiveButtonStatus  {
	LOADING = 'LOADING',
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	DEFAULT = 'DEFAULT'
}
export interface IReactiveButton {
	status: IReactiveButtonStatus;
	onClick?: ((arg: any) => any);
	disabled?: boolean;
	children: ReactNode;
	className?: string;
}
export interface ILangDependentImage {
	images: any[];
	imgAlt: string;
	className?: string;
	objectFit?: CSSProperties["objectFit"];
	objectPosition?: CSSProperties["objectPosition"];
}


export interface ITextField {
	label: string;
	value?: any;
	placeholder?: string;
	editable?: boolean;
	onClick?: () => void;
	handleEdit?: (string) => void;
}
export interface ILinkField {
	label: string;
	value: boolean;
	placeholder: string;
	onClick: () => void;
}
export interface ICheckmarkField {
	label: string;
	value: boolean;
	description: string;
	onClick: () => void;
}
export interface IMarketplaceProps extends ISectionQueryData {
	isLanding?: boolean;
}
export interface INavigationCard {
	link: string;
	external?: boolean;
	img: IGatsbyImageData;
	imgAlt: string;
	buttonText: string;
	title: string;
	description: string;
	icons?: FC[];
	iconKeys?: string[],
	disabled?: boolean;
	language?: string;
}

export interface IPrimeButtonProps {
	text: string;
	link?: string;
	external?: boolean;
	target?: '_self' | '_blank';
	onClick?: ((arg: any) => any);
	language?: string;
	disabled?: boolean;
	placeholder?: string;
	className?: string;
}

export interface IReelsProps {
	images: any[],
	delay?: number,
	speed?: number,
	reverseDirection?: boolean;
}

export interface IWinStepProps {
	img: IGatsbyImageData;
	imgAlt: string;
	title: string;
	description: string;
}

export interface IUserProviderProps {
	children: React.ReactNode
}

export interface ITournamentBracketData {
	finished?: boolean;
	bracket: IGroupBracket[];
}

export interface IGroupBracket {
	title: string;
	indicator: string;
	rounds: IRoundProps[];
}

export interface IGroupIndicator {
	roundInfo: IGroupBracket;
	currentRound: number;
	bracketsArray: IGroupBracket[];
}

export interface IGroupSelector {
	array: IGroupBracket[];
	currGroup: string;
	setRound: ((arg: number) => void);
}
export interface scoreType {
	scoreType: string;
	value: number;
}

export interface ILeaderboardEntryData {
	username: string;
	name: string;
	rank: number;
	avatarUrl: string;
	scores: scoreType[];
}

export interface ILeaderboardEntryProps extends ILeaderboardEntryData {
	imagesData: IGatsbyImageData;
}

export interface ILeaderboardProps {
	imagesData: IGatsbyImageData;
}

export interface ILeaderboardData {
	name: string;
	description?: string;
	startDate: string;
	endDate: string;
	leaderboard: ILeaderboardEntryData[];
}

export interface INewsLocalization {
	title: string;
	description: string;
	imgAlt: string;
	ctaText: string;
	imgUrl?: string;
}

export interface IBackgroundColor {
	to?: string;
	from?: string;
	plain?: string;
}

export interface INewsItem {
	es: INewsLocalization;
	en: INewsLocalization;
	bg: IBackgroundColor;
	link: string;
	imgUrl?: string;
	ctaDisabled?: boolean;
	noCta?: boolean;
	externalLink?: boolean;
	reverseOrder?: boolean;
}

export interface INews extends ISectionQueryData {
	news: INewsItem[];
}

export interface IFeatureVideo extends ISectionQueryData {
	videoId: string;
}
